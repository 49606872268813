import { getProject } from '~/api/projects';

const isNumber = (str: string) => !isNaN(Number(str));

export default defineNuxtRouteMiddleware(async (to) => {
  const project = await getProject(to.params.id.toString());
  if (isNumber(to.params.id.toString()) && project.slug?.length) {
    return navigateTo(`/projects/${project.slug}`, { redirectCode: 301 });
  }
});
